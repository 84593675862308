import { Component } from '@angular/core';
import { AccountManagementCardComponent } from '../desktop/account-management-card/account-management-card.component';
import { ComplianceSecurityCardComponent } from '../desktop/compliance-security-card/compliance-security-card.component';
import { IntegrationManagementCardComponent } from '../desktop/integration-management-card/integration-management-card.component';
import { NotificationsAlertsCardComponent } from '../desktop/notifications-alerts-card/notifications-alerts-card.component';
import { ReportingAnalyticsCardComponent } from '../desktop/reporting-analytics-card/reporting-analytics-card.component';
import { SupportHelpdeskCardComponent } from '../desktop/support-helpdesk-card/support-helpdesk-card.component';
import { SystemAdministrationCardComponent } from '../desktop/system-administration-card/system-administration-card.component';
import { TransactionManagementCardComponent } from '../desktop/transaction-management-card/transaction-management-card.component';
import { UserManagementCardComponent } from '../desktop/user-management-card/user-management-card.component';
import { BackupRecoveryCardComponent } from '../desktop/backup-recovery-card/backup-recovery-card.component';
import { DashboardService } from '../dashboard.service';



@Component({
  selector: 'app-customer',
  standalone: true,
  imports: [

  ],
  templateUrl: './customer.component.html',
  styleUrl: './customer.component.css'
})



export class CustomerComponent {

  totalAgentCount: number =0 ;
  totalCorporateCount: number =0 ;
  totalSubAgentCount: number =0 ;
  totalMerchantCount: number =0 ;
  totalCustomerCount: number =0 ;
  transactionCount: any;
  eMoneyBalance: any;
  setaraganBalance: any;
  activeServiceBalance: any;
  awccInventoryBalance: any;
  constructor(private dashboardService : DashboardService){}

  ngOnInit(){
    this.getAgentCount();
    this.getTotalCorporateCount();
    this.getTotalSubAgentCount();
    this.getTotalMerchantCount();
    this.getTotalCustomerCount();
    this.largeTransactionCount();
    this.getEmoneyBalance();
    this.getSetaraganBalance();
    this.getActiveServiceBalance();
    this.getAwccInventoryBalance();
  }

  getAgentCount(){
    this.dashboardService.getTotalAgentCount().subscribe(response =>{
      if(response.responseCode == 200){
        this.totalAgentCount = response.data;

      }
      else{
        alert("Network Error");
      }
    })
  }
  getTotalCorporateCount(){
    this.dashboardService.getTotalCorporateCount().subscribe(response =>{
      if(response.responseCode == 200){
        this.totalCorporateCount = response.data;

      }
      else{
        alert("Network Error");
      }
    })
  }
  getTotalSubAgentCount(){
    this.dashboardService.getTotalSubAgentCount().subscribe(response =>{
      if(response.responseCode == 200){
        this.totalSubAgentCount = response.data;

      }
      else{
        alert("Network Error");
      }
    })
  }
  getTotalMerchantCount(){
    this.dashboardService.getTotalMerchantCount().subscribe(response =>{
      if(response.responseCode == 200){
        this.totalMerchantCount = response.data;

      }
      else{
        alert("Network Error");
      }
    })
  }
  getTotalCustomerCount(){
    this.dashboardService.getTotalCustomerCount().subscribe(response =>{
      if(response.responseCode == 200){
        this.totalCustomerCount = response.data;

      }
      else{
        alert("Network Error");
      }
    })
  }
  largeTransactionCount(){
    this.dashboardService.largeTransactionCount().subscribe(response =>{
      if(response.responseCode == 200){
        this.transactionCount = response.data;

      }
      else{
        alert("Network Error");
      }
    })
  }
  getEmoneyBalance(){
    this.dashboardService.getEmoneyBalance().subscribe(response =>{
      if(response.responseCode == 200){
        this.eMoneyBalance = response.data;

      }
      else{
        alert("Network Error");
      }
    })
  }
  getSetaraganBalance(){
    this.dashboardService.getSetaraganBalance().subscribe(response =>{
      if(response.responseCode == 200){
        this.setaraganBalance = response.data;

      }
      else{
        alert("Network Error");
      }
    })
  }
  getActiveServiceBalance(){
    this.dashboardService.getActiveServiceBalance().subscribe(response =>{
      if(response.responseCode == 200){
        this.activeServiceBalance = response.data?.balance?.value;
        console.log("Active", this.activeServiceBalance);

      }
      else{
        alert("Network Error");
      }
    })
  }
  getAwccInventoryBalance(){
    this.dashboardService.getAwccInventoryBalance().subscribe(response =>{
      if(response.responseCode == 200){
        this.awccInventoryBalance = response.data;

      }
      else{
        alert("Network Error");
      }
    })
  }

}
