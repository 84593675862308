<div class="bg-gray-100 min-h-screen p-6">
        <div class="grid grid-cols-3 gap-6">
          <!-- Card 1 -->
          <div class="bg-green-300 p-4 rounded shadow">
            <h3 class="text-xl font-bold">Total Agent Count</h3>
            <p class="text-4xl font-semibold mt-2">{{totalAgentCount || 'NA'}}</p>
            <button class="text-blue-500 mt-2 underline">View more</button>
          </div>
      
          <!-- Card 2 -->
          <div class="bg-blue-300 p-4 rounded shadow">
            <h3 class="text-xl font-bold">Total Corporate Customer Count</h3>
            <p class="text-4xl font-semibold mt-2">{{totalCorporateCount || 'NA'}}</p>
            <button class="text-blue-500 mt-2 underline">View more</button>
          </div>
      
          <!-- Card 3 -->
          <div class="bg-purple-300 p-4 rounded shadow">
            <h3 class="text-xl font-bold">Total Individual Customer Count</h3>
            <p class="text-4xl font-semibold mt-2">{{totalCustomerCount || 'NA'}}</p>
            <button class="text-blue-500 mt-2 underline">View more</button>
          </div>
      
          <!-- Card 4 -->
          <div class="bg-blue-400 p-4 rounded shadow">
            <h3 class="text-xl font-bold">Total Merchant Count</h3>
            <p class="text-4xl font-semibold mt-2">{{totalMerchantCount || 'NA'}}</p>
            <button class="text-blue-500 mt-2 underline">View more</button>
          </div>
      
          <!-- Card 5 -->
          <div class="bg-teal-400 p-4 rounded shadow">
            <h3 class="text-xl font-bold">Total Subagent Count</h3>
            <p class="text-4xl font-semibold mt-2">{{totalSubAgentCount || 'NA'}}</p>
            <button class="text-blue-500 mt-2 underline">View more</button>
          </div>
      
          <!-- Card 6 -->
          <div class="bg-purple-400 p-4 rounded shadow">
            <h3 class="text-xl font-bold">E-Money</h3>
            <p class="text-4xl font-semibold mt-2">{{eMoneyBalance  || 'NA'}} AFN</p>
            <button class="text-blue-500 mt-2 underline">View more</button>
          </div>
      
          <!-- Card 7 -->
          <div class="bg-pink-300 p-4 rounded shadow">
            <h3 class="text-xl font-bold">Registered Customer Count</h3>
            <p class="text-4xl font-semibold mt-2">{{totalCustomerCount || 'NA'}}</p>
            <button class="text-blue-500 mt-2 underline">View more</button>
          </div>
      
          <!-- Card 8 -->
          <div class="bg-red-400 p-4 rounded shadow">
            <h3 class="text-xl font-bold">Large Cash Transaction Count</h3>
            <p class="text-4xl font-semibold mt-2">{{transactionCount || 'NA'}}</p>
            <button class="text-blue-500 mt-2 underline">View more</button>
          </div>
      
          <!-- Card 9 -->
          <div class="bg-green-400 p-4 rounded shadow">
            <h3 class="text-xl font-bold">AWCC Inventory Balance</h3>
            <p class="text-4xl font-semibold mt-2">{{awccInventoryBalance || 'NA'}} AFN</p>
            <button class="text-blue-500 mt-2 underline">View more</button>
          </div>
      
          <!-- Card 10  removed as no setaragn service availble in ABMMC-->
         <!--  <div class="bg-teal-400 p-4 rounded shadow">
            <h3 class="text-xl font-bold">Setaragan Balance</h3>
            <p class="text-4xl font-semibold mt-2">{{setaraganBalance || 'NA'}} AFN</p>
            <button class="text-blue-500 mt-2 underline">View more</button>
          </div> -->

          <!-- Card 11 -->
          <div class="bg-teal-400 p-4 rounded shadow">
                <h3 class="text-xl font-bold">Active Service Balance</h3>
                <p class="text-4xl font-semibold mt-2">{{activeServiceBalance || 'NA'}} AFN</p>
                <button class="text-blue-500 mt-2 underline">View more</button>
              </div>
        </div>
      
        <!-- User Profile Dropdown -->
        <div class="absolute top-4 right-4">
          <div class="relative group">
          <!--   <img
              src="path-to-user-profile-picture.jpg"
              alt="Admin Profile"
              class="w-10 h-10 rounded-full cursor-pointer"
            /> -->
            <div
              class="absolute right-0 mt-2 bg-white border rounded shadow-lg opacity-0 group-hover:opacity-100 transition duration-200">
              <p class="px-4 py-2 text-sm text-gray-700">admin</p>
              <p class="px-4 text-sm text-gray-500">admi123</p>
              <hr class="my-2">
              <button class="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100">
                Profile Picture
              </button>
              <button class="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100">
                Change Password
              </button>
              <button class="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100">
                Sign Out
              </button>
            </div>
          </div>
        </div>
      </div>
      